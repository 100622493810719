import React from "react"
import { connect } from "react-redux"
import ExtendedContext from "./components/ExtendedContext"
import ErrorPage from "./pages/Error"
import ConfigService from "./services/config"
import ZestyService from "./services/zesty"
import QuiqChatLibrary from "./lib/quiqchat"
import SentryLibrary from "./lib/sentry"
import { ZestyActions } from "./store/zesty/actions"
import { getSiteName, setPageTitle, applyTranslations, setTheme, isSiteAHS } from "./utils"
import {
	App as BlueprintApp,
	AppContext as BlueprintAppContext,
	DEFAULT_PROPS_CONTEXT_AHS_2024_THEME_VALUES,
	DefaultPropsContextProvider,
} from "@ftdr/blueprint-components-react"
import PageRouter from "./router"
import PageLoader from "./components/PageLoader"
import { OptimizelyActions } from "./store/optimizely/actions"
import { OPTIMIZELY_TOGGLE_VALUE_ON, OPTIMIZELY_VARIATIONS } from "./lib/Optimizely/constants"
import Cookies from "universal-cookie"

export class App extends React.Component {
	state = {
		isLoading: true,
		hasError: false,
		appSettings: {
			notificationsWidth: "50%",
			notificationsMinWidth: 320,
			notificationsMaxWidth: 600,
		},
	}

	handleError(e) {
		console.error(e)

		this.setState({ hasError: true })
	}

	/**
	 * Include tealium script, internally that will also include utag and hotjar on production
	 */
	includeTealiumScript() {
		const { tealium } = ConfigService.config
		const tealiumEnv = tealium?.env
		//Initialize Tealium + utag + hotjar
		if (tealiumEnv && typeof document !== "undefined") {
			// NOTE: The flag below is used to override the Tealium GA tag.
			window.utag_cfg_ovrd = { noview: true }
			window.utag_data = {}
			;(function (a, b, c, d) {
				a = `https://tags.tiqcdn.com/utag/americanhomeshield/${getSiteName()}/${tealiumEnv}/utag.js`
				b = document
				c = "script"
				d = b.createElement(c)
				d.src = a
				d.type = "text/java" + c
				d.async = true
				a = b.getElementsByTagName(c)[0]
				a.parentNode.insertBefore(d, a)
			})()
		}
	}

	addEZFlowCookie() {
		const cookie = new Cookies()
		if (window.location.pathname.includes("/lookup/")) {
			cookie.set("flowType", "EZRenewal")
		} else if (window.location.pathname.includes("/upgrade-lookup/")) {
			cookie.set("flowType", "EZChange")
		}
	}

	async componentDidMount() {
		try {
			this.addEZFlowCookie()

			await ConfigService.loadConfig()

			SentryLibrary.initialize()

			QuiqChatLibrary.addScript()
			QuiqChatLibrary.loadConfig()

			setPageTitle("MyAccount")
			setTheme()

			this.includeTealiumScript()

			const textTemplatesByLanguage = await ZestyService.getTranslations()
			const language = "en_US" // TODO: Load from cookie or something to remember user language whenever we support changing user language

			this.setState({
				appSettings: {
					...this.state.appSettings,
					language,
					textTemplatesByLanguage,
				},
			})

			await this.props.loadRequiredZestyContent()

			if (window.hideSummaryStep) {
				// Will be set by Optimizely Web during A/B test
				this.props.hideSummaryStepTest()
			}
		} catch (e) {
			this.handleError(e)
		}

		this.setState({ isLoading: false })
	}

	render() {
		if (this.state.isLoading) {
			return <PageLoader />
		} else if (this.state.hasError) {
			return <ErrorPage />
		} else {
			// Add "localizedContent" function to the app context
			const extendedContext = {
				localizedContent: (content) =>
					applyTranslations(content, this.state.appSettings.textTemplatesByLanguage, this.state.appSettings.language),
			}

			return (
				<BlueprintApp appSettings={this.state.appSettings}>
					<ExtendedContext value={extendedContext} Context={BlueprintAppContext}>
						<DefaultPropsContextProvider value={isSiteAHS() ? DEFAULT_PROPS_CONTEXT_AHS_2024_THEME_VALUES : null}>
							<PageRouter />
						</DefaultPropsContextProvider>
					</ExtendedContext>
				</BlueprintApp>
			)
		}
	}
}

const mapDispatchToProps = (dispatch) => ({
	loadRequiredZestyContent: () => {
		return Promise.all([dispatch(ZestyActions.getHeader(getSiteName())), dispatch(ZestyActions.getFooter(getSiteName()))])
	},
	hideSummaryStepTest: () => {
		return dispatch(OptimizelyActions.addVariation(OPTIMIZELY_VARIATIONS.NO_SUMMARY, OPTIMIZELY_TOGGLE_VALUE_ON))
	},
})

export default connect(null, mapDispatchToProps)(App)
