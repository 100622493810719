import React from "react"
import { connect } from "react-redux"
import {
	GlobalFooterGroupedLinksRowPreset,
	GlobalFooterMinimalFooterRowPreset,
	IconMail,
	IconMessage,
	IconPhone,
	Separator,
	HsaLogoColor,
	Ahs2024LogomarkColor,
} from "@ftdr/blueprint-components-react"
import { getUrlByType, isSiteAHS, isSiteHSA } from "../../utils"
import ConfigService from "../../services/config"
import { FooterID } from "../../services/homebase"
import classNames from "classnames"

const MinimumFooter = ({ details }) => {
	return (
		<GlobalFooterMinimalFooterRowPreset
			logoRenderer={getFooterLogo}
			legalText={details.legalText}
			links={details.links}
			facebook={details.social.Facebook}
			twitter={details.social.Twitter}
			youtube={details.social.Youtube}
			pinterest={details.social.Pinterest}
			linkedin={details.social.Linkedin}
		/>
	)
}

const getFooterLogo = () => {
	return isSiteAHS() ? (
		<Ahs2024LogomarkColor data-testid="AhsLogomarkColor" height={50} />
	) : (
		<HsaLogoColor data-testid="HsaLogoColor" height={50} />
	)
}

const FooterLinks = ({ linkGroups, alignment, accordionWhenMobileView }) => {
	return (
		<GlobalFooterGroupedLinksRowPreset linkGroups={linkGroups} justify={alignment} accordionWhenMobileView={accordionWhenMobileView} />
	)
}

export const Footer = (props) => {
	const { footerContent, className } = props

	const getIcon = (icon) => {
		switch (icon) {
			case "IconMessage":
				return <IconMessage size="22" />
			case "IconMail":
				return <IconMail size="22" />
			case "IconPhone":
				return <IconPhone size="22" />
			default:
				return null
		}
	}

	const isDownloadContractLink = (link) => {
		return link.id === FooterID.downloadContract
	}

	const mapLinks = (link) => ({
		children: link.menuText,
		className: "text-1",
		href:
			link.type === "MY_ACCOUNT"
				? ConfigService.config.site.myAccountDomain + getUrlByType(link.type, link.menuLink, ConfigService.config.external)
				: getUrlByType(link.type, link.menuLink, ConfigService.config.external),
		color: isSiteAHS() ? "interactive" : "primary",
		...(link.icon ? { startIcon: getIcon(link.icon), className: "text-interactive-700 text-1 font-bold" } : {}),
	})

	const filterLinks = (link) => {
		let include = true

		// download contract elements when user is logged out
		if (isDownloadContractLink(link)) {
			include = false
		}

		return include
	}

	const getFooterDetails = (data) => {
		return {
			section1: data.section1.map((item, i) => ({
				title: item.menuText,
				orientation: "vertical",
				links: item.children.filter(filterLinks).map(mapLinks),
				linkType: item.linkType || "normal",
			})),
			section2: {
				legalText: data.section2.legalText,
				social: data.section2.social,
				links: data.section2.links.map(mapLinks),
			},
		}
	}

	const details = footerContent ? getFooterDetails(footerContent) : null

	return (
		details && (
			<div
				id="page-footer"
				data-testid="PageFooter"
				className={classNames(
					{
						"bg-picket-white": isSiteAHS(),
						"bg-gray-100": isSiteHSA(),
					},
					className
				)}
			>
				<div className="container lg:border-t-1 border-gray-300">
					<FooterLinks alignment="between" accordionWhenMobileView={true} linkGroups={details.section1} />
					<MinimumFooter details={details.section2} />
					<Separator orientation="horizontal" gap="sm" />
				</div>
			</div>
		)
	)
}

const mapStateToProps = (state) => ({
	footerContent: state.zesty?.footer?.content,
})

export default connect(mapStateToProps)(Footer)
